import React from "react";
import Layout from "../components/Layout";
import Seo from "../components/Seo";
import monsters2 from '../assets/images/monsters2-method.png';
import iconTopDiv from '../assets/icons/icon-top-div-green.png';
import safeZone1 from '../assets/images/safe-zone1.png';
import { Link } from "gatsby";
import TheThreeRules from "../components/ui/sections/TheThreeRules";
import GetTheApp from "../components/ui/sections/GetTheApp";
import WaveTitle from "../components/ui/titles/WaveTitle";
import IconWaveTitle from "../components/ui/titles/IconWaveTitle";
import iconUmbrella from '../assets/icons/icon-umbrella.svg';
import Card from "../components/ui/cards/Card";
import iconHelm from '../assets/icons/icon-helm.png';
import iconWhat from '../assets/icons/icon-what.svg';
import iconLotus from '../assets/icons/icon-lotus.svg';
import iconSafehouse from '../assets/icons/icon-safehouse.svg';
import iconHand from '../assets/icons/icon-hand.svg';
import waveGraphic from '../assets/images/wave-graphic.svg';
import wavyBlue from '../assets/icons/wavy-blue.svg';
import How from "../components/content/How";

const TheSafeHarborMethod = () => {
    return (
        <Layout>
            <Seo seo={{ metaTitle: "The Basics" }} />
            <main>
                <section id="safe-harbor-introduction">
                    <div className="container small">
                        <div className="flex wrap between items-center">
                        <div className="flex column w-60">
                            <img className="w-20 mb-4" src={wavyBlue} alt="" style={{maxWidth: "150px"}} />
                            <h1 className="grey">The Safe Harbor Method™</h1>
                        </div>
                        {/* <img className="w-40" src={monsters2} alt="" /> */}
                        </div>
                    </div>
                </section>
                <section id="safe-harbor-zone" className="bg-purple relative">
                    <img src={iconTopDiv} className="absolute" style={{width: "150px", top: "0", left: "50%", transform: "translate(-50%, -50%)"}} />
                    <div className="container small">
                        <div>
                            <h1 className="blue text-center">Create a Safe Zone for Connection</h1>
                            <div className="mt-5 flex column between items-center">
                                <img src={safeZone1} className="w-30" />
                                <div className="mt-5">
                                    <p className="large text-center white mb-3">Agree to follow the 3 rules (below) and then alternate sharing and listening for <span className="orange">2 min</span> max with a timer. That’s it. Super simple and yet the start of something special.</p>
                                </div>
                            </div>
                        </div>
                        <div className="flex center gap-small mt-5">
                            <Link className="btn primary" to="#safe-harbor-how">How?</Link>
                            <Link className="btn primary" to="#safe-harbor-when">When?</Link>
                            <Link className="btn primary" to="#safe-harbor-why">Why?</Link>
                            <Link className="btn primary" to="#safe-harbor-what">What?</Link>
                            <Link className="btn primary" to="#safe-harbor-the-rules">The Three Rules</Link>
                        </div>  
                    </div>
                </section>
                <How />
                <section id="safe-harbor-when" className="">
                    <div className="container small">
                        <IconWaveTitle title="When?" icon={iconLotus} margin="mb-10" />
                        <div className="flex between gap">
                            <div className="w-60">
                                <p className="medium black">
                                    When is the best time to try the Safe Harbor Method™? We recommend practicing this communication technique on a regular schedule so that we can be ready for the storm. When a disagreement or challenging moment arrives, or when you begin to feel distant, this is the perfect time for SHM. Productive communication and deeper connection will be much more effective in the calm conditions of a Safe Harbor.
                                </p>
                            </div>
                            <div className="w-30">
                                <Card 
                                    icon={iconHand}
                                >
                                    <p className="medium blue mt-4 text-center">There’s a time to wait and a there’s a time to connect</p>
                                </Card>
                            </div>

                        </div>
                        <ul className="medium before-blue black mt-5">
                            <li>
                                When the storm arrives, it will be easier to just stop and wait for the big clouds to pass. 
                            </li>
                            <li>
                                Productive connection will happen in calming or calm conditions.
                            </li>
                            <li>
                                The words we use, and our body language can affect the weather.
                            </li>
                        </ul>
                        <div className="flex column items-center gap mt-10">
                            <img src={waveGraphic} style={{maxWidth: "100%"}} alt="" />
                            <p className="medium black text-center w-80 mt-5">
                                One of the people engaged in the disagreement needs to become aware that the heat of the exchange is rising, 
                                and a physical sensation or angry thoughts will often be apparent. 
                                At that moment the person who has begun to feel overwhelmed needs to say something like: “I need a time out,” or 
                                “I’m feeling overwhelmed,” or “I need to stop.”
                            </p>
                        </div>
                    </div>
                </section>
                <section id="safe-harbor-why" className="no-padding-bottom">
                    <div className="container small">
                        <IconWaveTitle title="Why?" icon={iconUmbrella} margin="mb-10" />
                        <div className="flex between gap">
                            <div className="w-60">
                                <p className="medium black">
                                    There are countless books, workshops, communication systems, etc. and yet the divorce rate is still 50%. These options are important, but is there something more that we can do? Something that can be done at home without months of training and bills? Something that fits into our partner’s busy schedule?
                                </p>
                                <ul className="medium before-blue black mt-5">
                                    <li>
                                        Disagreements, arguments and fights will often resolve once listening, uninterrupted listening, occurs between people. 
                                    </li>
                                    <li>
                                        If using the SHM doesn’t resolve an issue, it will likely pave the way for discovering solutions and revealing the direction to move towards finding a resolution.
                                    </li>
                                </ul>
                            </div>

                            <div className="w-30">
                                <Card 
                                    icon={iconHelm}
                                >
                                    <p className="medium blue mt-4 text-center">Most haven’t learned how to <span className="bolder">navigate</span> heated disagreements or productively engage with those with opposing viewpoints.</p>
                                </Card>
                            </div>

                        </div>
                    </div>
                </section>
                <section id="safe-harbor-what" className="no-padding-bottom">
                    <div className="container small">
                        <IconWaveTitle title="What?" icon={iconWhat} margin="mb-10" />
                        <div className="flex between gap">
                            <div className="w-60">
                                {/* <p className="medium black">
                                    <p className="bolder italic">What is a Safe Harbor?</p> It is an environment of trust, of vulnerability where we can have compassionate communication.
                                </p> */}
                                <p className="medium black">
                                <span className="bolder italic">What is The Safe Harbor Method™ (SHM)?</span> SHM is a specific technique of communicating with your partner in order to create a safe space for sharing and empathy. The format is simple, the effect is profound. The Safe Harbor Method™ is a simple technique that is:
                                </p>
                                <ul className="medium before-blue black">
                                    <li>Free
                                    </li>
                                    <li>
                                        Intuitive
                                    </li>
                                    <li>
                                        Easy to Learn
                                    </li>
                                    <li>
                                        Instantly Accessible during Stormy Weather
                                    </li>
                                </ul>
                            </div>

                            <div className="w-30">
                                <Card 
                                    icon={iconSafehouse}
                                >
                                    <p className="medium blue mt-4 text-center">
                                        When the waters get rough, stop and return to a safe harbor. Listen to eachother in equal portions.</p>
                                </Card>
                            </div>

                        </div>
                    </div>
                </section>
                
                <TheThreeRules/>
                <GetTheApp/>
                <section>
                    <div className="container small flex column items-center">
                        <WaveTitle title="Get Started?" margin="mb-5"/>
                        <div className="flex center gap">
                            <Link className="btn primary large" to="/self-guided-sessions">Self Guided Sessions</Link>
                            <Link className="btn primary large" to="/choose-a-guide">Choose A Guide</Link>
                        </div>
                    </div>
                </section>
            </main>
        </Layout>
    )
}

export default TheSafeHarborMethod;